import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Link } from "gatsby";
import etsyLogo from "../images/etsy-icon.svg";

export default function Shop() {
  return (
    <Layout>
      <SEO
        title="Shop"
        description="Shop existing artwork, or request a commission online"
      />
      <main className="w-full flex-grow max-w-screen-xl mx-auto py-4 px-4-safe-area sm:px-6-safe-area" role="main">
        <h1 className="text-3xl pt-1 pb-2 md:text-4xl md:pb-4 md:pt-5 lg:pt-6">Shop Online</h1>
        <div className="text-md">
        <p className="mb-3">We sometimes have a limited number of existing artwork available for purchase at&nbsp;
          <a href="https://colourstormart.etsy.com" rel="noreferrer" className="underline whitespace-nowrap">
            <img alt="Etsy" className="w-3.5 inline pr-1" src={etsyLogo} />colourstormart.etsy.com
          </a>.
        </p>
        <p className="mb-3">If you're unable to find what you're looking for or to request your own custom artwork please <Link className="underline" to="/contact/">contact us</Link>.</p>
        <p className="mb-6">Emma &amp; Jack @ ColourStormArt</p>
        <a href="https://colourstormart.etsy.com" rel="noreferrer" className="mb-10 w-full block md:inline-block md:w-auto px-2.5 py-1.5 rounded text-center text-black bg-white hover:underline">Visit Online Shop</a>
        </div>
      </main>
    </Layout>
  )
}